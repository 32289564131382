<template>
  <div>
    <div class="o_m" id="o_a"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import "echarts/extension/bmap/bmap.js";
export default {
  props: {
    allPointsList: {
      type: Object,
      default: {},
    },
    migrationPointsList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      geoCoordMap_o_a: {},
      arr: [],
    };
  },
  watch: {
    allPointsList: {
      handler(newVal) {
        this.geoCoordMap_o_a = newVal;
        console.log(this.geoCoordMap_o_a, "子：allPointsList");
      },
      deep: true,
      immediate: true,
    },
    migrationPointsList: {
      handler(newVal) {
        this.arr = newVal;
        console.log(this.migrationPointsList, "子：migrationPointsList");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    createBmapAPI() {
      const AK = "53bK8PCkj7oWwZWsWeXPtRAo9qefdVhw";
      const BMap_URL =
        "https://api.map.baidu.com/api?v=2.0&ak=" +
        AK +
        "&s=1&callback=onBMapCallback";
      return new Promise((resolve, reject) => {
        // 如果已加载直接返回
        if (typeof BMap !== "undefined") {
          resolve(BMap);
          return true;
        }
        // 百度地图异步加载回调处理
        window.onBMapCallback = function () {
          console.log("百度地图脚本初始化成功...");
          resolve(BMap);
        };

        // 插入script脚本
        let scriptNode = document.createElement("script");
        scriptNode.setAttribute("type", "text/javascript");
        scriptNode.setAttribute("src", BMap_URL);
        document.body.appendChild(scriptNode);
      });
    },
    async init() {
      await this.$nextTick();
      const that = this;
      let mapDiv = document.getElementById("o_a");
      let myChart = echarts.init(mapDiv);
      //三个出发点/终点的颜色
      var color_o_a = [];
      var newArr = [];
      this.arr.forEach((v, i) => {
        newArr[i] = [v[0].name, [v]];
        color_o_a[i] = color16();
      });
      //小飞机样式也可以换成图片路径引用，能方便点，参考我之前写的’echarts自定义柱状图柱体形状‘这篇文章
      // var planePath_o_a =
      //   "path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z";
      var planePath_o_a = "image://";
      //转换数据,将serier列表中配置的样式转换为data
      var convertData_o_a = (data) => {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var dataItem = data[i];
          var fromCoord = this.geoCoordMap_o_a[dataItem[0].name];
          var toCoord = this.geoCoordMap_o_a[dataItem[1].name];
          if (fromCoord && toCoord) {
            res.push({
              fromName: dataItem[0].name,
              toName: dataItem[1].name,
              coords: [fromCoord, toCoord],
            });
          }
        }
        return res;
      };
      function color16() {
        //十六进制颜色随机
        var r = Math.floor(Math.random() * 256);
        var g = Math.floor(Math.random() * 256);
        var b = Math.floor(Math.random() * 256);
        var color = "#" + r.toString(16) + g.toString(16) + b.toString(16);
        return color;
      }
      //系列列表。每个系列通过 type 决定自己的图表类型，这里相当于三个出发点/终点的三个配置，用下标表示（例如：name: item[0],）
      var series_o_a = [];
      console.log(newArr, "NewArr");
      newArr.forEach((item, i) => {
        series_o_a.push(
          {
            //第一个点
            name: item[0],
            //涟漪特效类型
            type: "effectScatter",
            //地理坐标系采用bmap里配置的百度地图
            coordinateSystem: "bmap",
            //用于 Canvas 分层（相当于z-index），z相比zlevel优先级更低，而且不会创建新的 Canvas,为了使点位于底图（bmap配置的百度地图）之上
            zlevel: 2,
            //涟漪特效相关配置
            rippleEffect: {
              //波纹的绘制方式可选 'stroke' 和 'fill'。 相当于丢石头那个涟漪效果，一个类似圆环，一个类似圆饼
              brushType: "fill",
            },
            //配置线条样式
            label: {
              normal: {
                show: true,
                position: "right",
                //采用 rich 中定义样式。
                formatter: "{b}",
              },
            },

            symbolSize: function (val) {
              return val[2] / 10;
            },

            //配置何时显示特效。'render' 绘制完成后显示特效  'emphasis' 高亮（hover）的时候显示特效。
            showEffectOn: "render",

            itemStyle: {
              normal: {
                color: color_o_a[i],
              },
            },
            data: [
              {
                name: item[0],
                value: that.geoCoordMap_o_a[item[0]].concat([100]),
              },
            ],
          },
          {
            name: item[0] + " Top10",
            type: "lines",
            coordinateSystem: "bmap",
            zlevel: 1,
            effect: {
              show: true,
              period: 6,
              trailLength: 0.7,
              color: "#fff",
              symbolSize: 3,
            },
            lineStyle: {
              normal: {
                color: color_o_a[i],
                width: 0,
                curveness: 0.2,
              },
            },
            data: convertData_o_a(item[1]),
          },

          {
            name: item[0] + " Top10",
            type: "lines",
            coordinateSystem: "bmap",
            zlevel: 2,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              symbol: planePath_o_a,
              symbolSize: 15,
            },
            lineStyle: {
              normal: {
                color: color_o_a[i],
                width: 1,
                opacity: 0.4,
                curveness: 0.2,
              },
            },
            data: convertData_o_a(item[1]),
          },

          {
            name: item[0] + " Top10",
            type: "effectScatter",
            coordinateSystem: "bmap",
            zlevel: 2,
            rippleEffect: {
              brushType: "stroke",
            },
            label: {
              normal: {
                show: true,
                position: "right",
                formatter: "{b}",
              },
            },
            symbolSize: function (val) {
              return val[2] / 10;
            },
            showEffectOn: "render",
            itemStyle: {
              normal: {
                color: color_o_a[i],
              },
            },
            data: item[1].map(function (dataItem) {
              return {
                name: dataItem[1].name,
                value: that.geoCoordMap_o_a[dataItem[1].name].concat([50]),
              };
            }),
          }
        );
      });

      let bmapStyle = [
        {
          featureType: "water",
          elementType: "all",
          stylers: {
            color: "#021019",
          },
        },
        {
          featureType: "highway",
          elementType: "geometry.fill",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "highway",
          elementType: "geometry.stroke",
          stylers: {
            color: "#147a92",
          },
        },
        {
          featureType: "arterial",
          elementType: "geometry.fill",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "arterial",
          elementType: "geometry.stroke",
          stylers: {
            color: "#0b3d51",
          },
        },
        {
          featureType: "local",
          elementType: "geometry",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "land",
          elementType: "all",
          stylers: {
            color: "#08304b",
          },
        },
        {
          featureType: "railway",
          elementType: "geometry.fill",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "railway",
          elementType: "geometry.stroke",
          stylers: {
            color: "#08304b",
          },
        },
        {
          featureType: "subway",
          elementType: "geometry",
          stylers: {
            lightness: -70,
          },
        },
        {
          featureType: "building",
          elementType: "geometry.fill",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: {
            color: "#857f7f",
          },
        },
        {
          featureType: "all",
          elementType: "labels.text.stroke",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "building",
          elementType: "geometry",
          stylers: {
            color: "#022338",
          },
        },
        {
          featureType: "green",
          elementType: "geometry",
          stylers: {
            color: "#062032",
          },
        },
        {
          featureType: "boundary",
          elementType: "all",
          stylers: {
            color: "#1e1c1c",
          },
        },
        {
          featureType: "manmade",
          elementType: "geometry",
          stylers: {
            color: "#022338",
          },
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: {
            visibility: "off",
          },
        },
        {
          featureType: "all",
          elementType: "labels.icon",
          stylers: {
            visibility: "off",
          },
        },
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: {
            color: "#2da0c6",
            visibility: "on",
          },
        },
        {
          featureType: "background",
          elementType: "all",
          stylers: {
            color: "#0e1054ff",
          },
        },
      ];
      let options = {
        title: {
          text: "",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          triggerOn: "click",
          show: false,
        },
        bmap: {
          //百度地图配制
          center: [119.302903, 26.080553], // 中心点
          zoom: 8, // 缩放
          roam: true, // 是否拖拽
          mapStyle: {
            styleJson: bmapStyle, //地图样式配制
          },
        },
        series: series_o_a,
      };
      console.log(options, "xx");
      myChart.setOption(options, true);
    },
  },
  async mounted() {
    await this.createBmapAPI();
    this.init();
  },
};
</script>

<style scoped>
.o_m {
  width: 100%;
  height: 93vh;
}
</style>
