import request from '@/utils/request'

// 省
export const getProvincialList = () => {
    return request({
        method: 'GET',
        url: '/external/area/provincial-list',
        params: {
            pcode: "45",
        },
    })
}

// 市
export const getCityList = ParentCode => {
    return request({
        method: 'GET',
        url: '/external/area/city-list',
        params: {
            ParentCode
        }
    })
}

// 县
export const getDistrictList = ParentCode => {
    return request({
        method: 'GET',
        url: '/external/area/district-list',
        params: {
            ParentCode
        }
    })
}

// 乡
export const getTownList = ParentCode => {
    return request({
        method: 'GET',
        url: '/external/area/town-ship-list',
        params: {
            ParentCode
        }
    })
}

// 村
export const getVillageList = ParentCode => {
    return request({
        method: 'GET',
        url: '/external/area/village-list',
        params: {
            ParentCode
        }
    })
}